body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.display-on-print {
  display: none;
}

@media print {
  * {
    font-size: 14px;
  }
  .form-control {
    border: 0;
  }
  .display-on-print {
    display: block;
  }
  .hide-on-print {
    display: none;
  }
  .table td,
  .table th {
    padding: 2px 10px;
  }
  .table-warning {
    background-color: red;
  }
  .input-group-text {
    background: none;
    border: 0;
  }
}
